
/*

import "./src/styles/vendor/bootstrap.min.css"
import "./src/styles/vendor/linearicons.min.css"
import "./src/styles/vendor/fontawesome-all.min.css"
import "./src/styles/plugins/animation.min.css"
import "./src/styles/plugins/slick.min.css"
import "./src/styles/plugins/magnific-popup.css"
import "./src/styles/plugins/easyzoom.css"
import "./src/styles/style.css"

import "./src/styles/global.css"


import "./src/js/plugins/fullpage.min.js"
import "./src/js/plugins/slick.min.js"
import "./src/js/plugins/countdown.min.js"
import "./src/js/plugins/magnific-popup.js"
import "./src/js/plugins/easyzoom.js"
import "./src/js/plugins/images-loaded.min.js"
import "./src/js/plugins/wow.min.js"
*/


const $ = require("jquery")

export const onInitialClientRender = () => {
  $(document).ready(function () {
  });
}
